<template>
  <section>
    <div class="header-page">
      <div class="container">
        <h1 data-aos="fade-up">Sistema de Ensino</h1>
        <p class="subtitulo" data-aos="fade-down">
          A solução mais prática para fidelizar alunos, professores e gestores
          ao seu sistema de ensino.
        </p>
      </div>
    </div>

    <div class="container page">
      <div class="text" data-aos="fade-down">
        <p>
          Com nosso AVA, a sua rede de ensino pode manter a própria identidade,
          criando um espaço exclusivo e personalizado, com material didático
          espelhado, contando com mais de 5 mil desafios do ensino infantil ao
          ensino médio, todos parametrizados conforme a BNCC (Base Nacional
          Comum Curricular). Nossos serviços ainda oferecem ferramentas
          educacionais interativas, um completo sistema de engajamento aos
          alunos, relatórios estratégicos ao professor, à escola, às prefeituras
          e à toda a gestão do seu negócio.
        </p>
        <div class="valores-empresa">
          <div class="valor-item">
            <h2>Principais benefícios:</h2>
            <p>
              <br />• O melhor custo-benefício do mercado; <br />• Ferramenta de
              autoria de jogos; <br />• Solução de avaliação continuada; <br />•
              Recursos de engajamento e incentivos; <br />• Educador
              protagonista; <br />• Conteúdos e propostas alinhadas à BNCC;
              <br />• Assessoria de tecnologia educacional; <br />• Ambientes de
              interação entre professor e aluno; <br />• Design segmentado por
              ciclo e idade; <br />• Customização em acordo com a sua marca ou
              com a nossa; <br />• Relatórios de gestão inteligente; <br />•
              Acervo com milhares de materiais e conteúdos inclusos.
            </p>
          </div>
          <div class="valor-item">
            <h2>Ideal para:</h2>
            <p>
              <br />• Sistemas de ensino de diferentes portes e regiões do país;
              <br />• Sistemas de ensino do segmento de ensino básico; <br />•
              Sistemas de ensino que desejem oferecer um portal de atividades
              integradoras.
            </p>
          </div>
        </div>
      </div>
      <div class="imagem">
        <img
          src="@/assets/img/sistema-de-ensino.png"
          alt="sistema"
          class="foto-page"
          data-aos="fade-left"
        />
        <img
          src="@/assets/icons/detalhe_01.svg"
          class="detalhe"
          data-aos="fade-right"
        />
      </div>
    </div>
    <div class="container segmentos">
      <Carrosel :cards="cards" :segmentos="true">
        <div>
          <h2>Conheça <b>outros segmentos</b></h2>
          <p class="sub">
            Conheça mais sobre outros segmentos e descubra como podemos atuar na
            sua empresa.
          </p>
        </div>
      </Carrosel>
    </div>
  </section>
</template>

<script>
import Carrosel from "@/components/Carrosel";

export default {
  name: "sistema",
  data() {
    return {
      cards: [
        {
          path: "icons/icon-distribuidores.svg",
          title: "Distribuidores",
          date: "",
          text: "A oferta digital mais criativa para o seu portfólio.",
          link: "/distribuidores",
        },
        {
          path: "icons/icon-editoras.svg",
          title: "Editoras",
          date: "",
          text: "O AVA com a cara da sua editora. Conectada, interativa e 100% digital.",
          link: "/editoras",
        },
        {
          path: "icons/icon-prefeitura.svg",
          title: "Prefeituras e Governos",
          date: "",
          text: "Sem essa de versão light. A mesma tecnologia para rede pública e privada.",
          link: "/prefeituras-e-governos",
        },
        {
          path: "icons/icon-empresas.svg",
          title: "Empresas e Promoções",
          date: "",
          text: "Marketing inteligente que fideliza e aumenta as suas vendas.",
          link: "/empresas-e-promocoes",
        },
        {
          path: "icons/icon-cooporativo.svg",
          title: "Corporativo/RH",
          date: "",
          text: "Estimule o desenvolvimento e potencial de seus colaboradores.",
          link: "/coportativo-rh",
        },
        {
          path: "icons/icon-cursos.svg",
          title: "Cursos e Ensino Técnico",
          date: "",
          text: "Qualidade, conteúdo e o engajamento que a sua instituição precisa em um só lugar!",
          link: "/cursos-e-ensino-tecnico",
        },
        {
          path: "icons/icon-universidade.svg",
          title: "Universidade",
          date: "",
          text: "Qualidade, conteúdo e o engajamento que a sua instituição precisa em um só lugar!",
          link: "/universidades",
        },
      ],
    };
  },
  components: {
    Carrosel,
  },
  created() {
    document.title = "Atheva | Sistema de Ensino";
  },
};
</script>
